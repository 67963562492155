<template>
  <ckeditor
    ref="editor"
    :id="editorId"
    :outlined="outlined"
    :editor-url="editorUrl"
    :config="editorConfig"
    :value="textContent"
    tag-name="textarea"
    @input="setContent($event)"
  >
  </ckeditor>
</template>
  
  <script>
export default {
  name: "CkEditorComponent",
  props: {
    textContent: {
      type: String,
      default: "",
    },
    editorId: {
      type: String,
      default: "editor",
    },
    outlined: {
      type: String,
      default: "outlined",
    },
  },
  data() {
    return {
      editorUrl: "https://assets.milady-rh.com/ckeditor/ckeditor.js",
      editorConfig: {
        extraPlugins: "image,codemirror",
        removePlugins: "easyimage, cloudservices,forms,iframe,exportpdf,smiley,scayt,a11yhelp,showblocks,magicline,language,newpage,pagebreak,div,templates,colordialog",
        filebrowserBrowseUrl: "https://media.milady-rh.com/upload/fileBrowser.php",
        filebrowserUploadUrl:
          "https://uat-api.milady-rh.com/admin/media/upload?type=Images&responseType=json&_token=",
      },
    };
  },
  methods: {
    setContent(event) {
      this.$emit("get-text", event);
    },
  },
};
</script>
  